<template>
  <section class="two-columns-block">
    <div class="container">
      <div
        class="two-columns-block__content"
        :class="contentClassName"
      >
        <div class="two-columns-block__left" :class="leftClassName">
          <slot name="left" />
        </div>
        <div class="two-columns-block__right" :class="rightClassName">
          <slot name="right" />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { IBlockDefaultProps } from "#sitis/internal/models/common";
import type { ComputedRef } from 'vue';

const props = defineProps<IBlockDefaultProps>();

const leftClassName: ComputedRef<string> = computed(() => {
  const width: Number = Number.parseFloat(props?.extras?.leftWidth || '0');
  return `--width-${ width }`
});
const rightClassName: ComputedRef<string> = computed(() => {
  const width: Number = Number.parseFloat(props?.extras?.rightWidth || '0');
  return `--width-${ width }`
});
const contentClassName: ComputedRef<string> = computed(() => {
  const gap: Number = Number.parseFloat(props?.values?.gap || '0');
  return `--gap-${ gap }`
});
</script>

<style lang="scss">
.two-columns-block__content {
  display: flex;

  @for $i from 1 through 12 {
    &.--gap-#{$i} {
      gap: $i * 6px;
    }
  }
}
.two-columns-block__left,
.two-columns-block__right {
  display: flex;
  flex-direction: column;
  flex: auto;

  @for $i from 1 through 12 {
    &.--width-#{$i} {
      width: calc(100% / 12 * #{$i});
      max-width: calc(100% / 12 * #{$i});
    }
  }
}
.two-columns-block__left > div,
.two-columns-block__left > div > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media (max-width: 1023px) {
  .two-columns-block__content {
    flex-direction: column;
  }
  .two-columns-block__left,
  .two-columns-block__right {
    width: 100%!important;
    max-width: 100%!important;
  }
}
</style>



